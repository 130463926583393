.group {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: thin solid #cccccc;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.group.bangumihyo {
  border: none;
  height: 262px;
}

.group.content {
  border: none;
  overflow-y: auto;
}

.hedding {
  display: flex;
  align-items: center;
  margin: 4px;
  font-size: 1.2rem;
}

.count {
  display: inline-block;
  min-width: 12px;
  padding: 5px 11px;
  font-size: 0.8rem;
  font-weight: normal;
  color: white;
  line-height: 1.5;
  text-align: center;
  background-color: #E68031;
  border: 1px solid transparent;
  border-radius: 2em;
  margin-right: 8px;
  margin-bottom: 8px;
  z-index: 1;
}

.saiseijun-container {
  position: relative;
  width: 100%;
}

.saiseijun {
  position: absolute;
  top: -10px;
  left: -10px;
}

.list {
  display: flex;
  align-content: flex-start;
  flex-grow: 1;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 10px;
  height: 100%;
  min-height: 260px;
  max-height: 490px;
}

.bangumihyo .list {
  overflow-x: auto;
}

.content .list {
  flex-wrap: wrap;
}

.item-wrapper {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  margin-left: 20px;
}

.bangumihyo-container {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: hidden;
}

.arrow-button {
  min-width: 48px !important;
  max-width: 48px !important;
  padding: 0px 8px !important;
  margin: 4px !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}