.group {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: thin solid #cccccc;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.group.bangumihyo {
  border: none;
  height: 270px;
}

.list {
  display: flex;
  align-content: flex-start;
  overflow-x: auto;
  flex-grow: 1;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 10px;
  height: 100%;
  min-height: 260px;
  max-height: 490px;
}

.item-wrapper {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  margin-left: 12px;
}

.contents {
  display: flex;
  flex-flow: column;
  cursor: pointer;
}

.thumbnail-text {
  max-width: 300px;
  padding: 5px;
  font-size: medium;
  background-color: white;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnail-text p {
  margin: 0;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-container {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: hidden;
}
