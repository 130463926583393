.playlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item-wrapper {
  display: flex;
  align-items: center;
  flex-flow: row;
  margin-left: 12px;
}

.play-arrow-container {
  width: 50px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contents {
  display: flex;
  justify-content: center;
  flex-flow: column;
  cursor: pointer;
}

.thumbnail-text {
  max-width: 200px;
  padding: 5px;
  font-size: medium;
  background-color: white;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}